import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import rehypeRemoveComments from "rehype-remove-comments";
import rehypeVideo from "rehype-video";
import "katex/dist/katex.min.css";
const _mapProps = (props) => ({
  ...props,
  remarkPlugins: [[remarkGfm, { singleTilde: false }], [remarkMath]],
  rehypePlugins: [[rehypeKatex], [rehypeRemoveComments], [rehypeVideo]],
});

const Markdown = (props) => <ReactMarkdown {..._mapProps(props)} />;

export default Markdown;
