import React from "react";
import "./style.css";
import Modal from "../modal";
const projects_data = [
  // {
  //   id: "projects_0",
  //   title: "Cartobat",
  //   image: "/assets/projects/cartobat.png",
  //   description:
  //     "Python package for Bluetooth Low Energy indoor localization data analysis and visualization.",
  //   md_file: "cartobat.md",
  //   git_repo: "https://github.com/gatienc/Cartobat",
  // },

  {
    id: "projects_1",
    title: "InsidePSBS",
    image: "/assets/projects/insidepsbs.png",
    description:
      "Mobile app designed as a social media for my engineering school.",
    md_file: "insidepsbs.md",
    git_repo: "https://github.com/info-telecom-strasbourg/InsidePSBS",
  },
  {
    id: "projects_2",
    title: "Emoji NLP",
    image: "/assets/projects/emoji2vec.png",
    description:
      "Natural Language Processing project on research about emoji vectorization.",
    md_file: "emoji2vec.md",
    git_repo: "https://github.com/gatienc/emoji2vec_pytorch",
  },
  {
    id: "projects_3",
    title: "MarcoNeo",
    image: "/assets/projects/raspberry.jpg  ",
    description: "Local payment system administrator.",
    git_repo: "https://github.com/bde-tps/marconeo",
    md_file: "marconeo.md",
  },
];
const ProjectComponent = (data) => {
  return (
    <div
      className="ProjectCard"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.9) 30%, rgba(255,255,255,0) 80%),url(${data.image})`,
      }}
    >
      <section className="ProjectCard_bottom">
        <h3>{data.title}</h3>
        {data.description}
      </section>
    </div>
  );
};

const Projects = () => {
  var project_list = projects_data.map((data) => {
    return (
      <Modal
        key={data.id}
        component={<ProjectComponent key={data.id} {...data} />}
        data={data}
      />
    );
  });

  return <div className="ProjectsContainer">{project_list}</div>;
};
export default Projects;
