import React from "react";
export default function Linkedin() {
    return (
      <svg
        height="72"
        viewBox="0 0 72 72"
        width="72"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path
            d="M36,72 L36,72 C55.882251,72 72,55.882251 72,36 L72,36 C72,16.117749 55.882251,-3.65231026e-15 36,0 L36,0 C16.117749,3.65231026e-15 -2.4348735e-15,16.117749 0,36 L0,36 C2.4348735e-15,55.882251 16.117749,72 36,72 Z"
            fill="currentColor"
          />
          <path
            d="M59,57 L49.959375,57 L49.959375,41.6017895 C49.959375,37.3800228 48.3552083,35.0207581 45.0136719,35.0207581 C41.3785156,35.0207581 39.4792969,37.4759395 39.4792969,41.6017895 L39.4792969,57 L30.7666667,57 L30.7666667,27.6666667 L39.4792969,27.6666667 L39.4792969,31.6178624 C39.4792969,31.6178624 42.0989583,26.7704897 48.3236979,26.7704897 C54.5455729,26.7704897 59,30.5699366 59,38.4279486 L59,57 Z M20.372526,23.8257036 C17.4048177,23.8257036 15,21.4020172 15,18.4128518 C15,15.4236864 17.4048177,13 20.372526,13 C23.3402344,13 25.7436198,15.4236864 25.7436198,18.4128518 C25.7436198,21.4020172 23.3402344,23.8257036 20.372526,23.8257036 Z M15.8736979,57 L24.958724,57 L24.958724,27.6666667 L15.8736979,27.6666667 L15.8736979,57 Z"
            fill="#FFF"
          />
        </g>
      </svg>
      //   <svg
      //     width="24px"
      //     height="24px"
      //     strokeWidth="1.5"
      //     viewBox="0 0 24 24"
      //     fill="none"
      //     xmlns="http://www.w3.org/2000/svg"
      //     color="#000000"
      //   >
      //     <path
      //       d="M21 8v8a5 5 0 01-5 5H8a5 5 0 01-5-5V8a5 5 0 015-5h8a5 5 0 015 5zM7 17v-7"
      //       stroke="currentColor"
      //       strokeWidth="1.5"
      //       strokeLinecap="round"
      //       strokeLinejoin="round"
      //     ></path>
      //     <path
      //       d="M11 17v-3.25M11 10v3.75m0 0c0-3.75 6-3.75 6 0V17M7 7.01l.01-.011"
      //       stroke="currentColor"
      //       strokeWidth="1.5"
      //       strokeLinecap="round"
      //       strokeLinejoin="round"
      //     ></path>
      // </svg>
    );
}
