import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <footer className="footer">
      <ul>
        <li>
          <a href="https://github.com/gatienc">Github</a>
        </li>
        <li>
          <a href="/resume.pdf">Download CV</a>
        </li>
        <li>
          <a href="https://linkedin.com/in/gatien-chenu/">LinkedIn</a>
        </li>
        <li>
          <a href="mailto:gatien@chenu.me">Contact Me</a>
        </li>
      </ul>
      {/* <ul>
        <li>
          <a href="#home">Home</a>
        </li>
         <li>All Projects</li> 
         <li>Legal Mention</li> 
      </ul> */}
      <div className="copyright">Copyrigth © 2024 Gatien Chenu</div>
    </footer>
  );
};

export default Footer;
