import React from "react";
import "./style.css";

export default function LinkButton({ link, text, SvgComponent }) {
  return (
    <a className="LinkButton" href={link}>
      <div className="LinkButton__text">{text}</div>
      <div className="LinkButton__icon">
        <SvgComponent />
      </div>
    </a>
  );
}
