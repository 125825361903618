import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import './style.css'
import Markdown from '../markdown';

const Modal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch("/markdown/"+props.data.md_file)
    .then((res) => res.text())
    .then((text) => {
      if (text.startsWith("<")) {
        setMarkdown("## Sorry, this project has not been documented yet.");
      }else{
        setMarkdown(text);
      }
    });
  }, [props.data.md_file]);
  

  const handleOpenModal = () => {
    setShowModal(true);
  }
  
  const handleCloseModal = () => {
    setShowModal(false);
  }


  

  return (
    <div>
      <div onClick={handleOpenModal}>{props.component}</div>
      <ReactModal 
         isOpen={showModal}
         contentLabel="onRequestClose Example"
         onRequestClose={handleCloseModal}
         className="Modal"
         overlayClassName="ModalOverlay"
         ariaHideApp={false}
      >
        <div className='ModalHeader'>
          <h1>{props.data.title}</h1>
          <button className='close' onClick={handleCloseModal}>close</button>
        </div>
        <div className="ModalSeparatorLine"/>
        <Markdown className='ModalMarkdown'>{markdown}</Markdown>
      </ReactModal>
    </div>
  );
}

export default Modal;