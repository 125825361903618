import React from "react";
import "./style.css";

const Separator = ({title,id}) => {
    return (
        <div className="Separator" id={id}>
            <h2 className="SeparatorText">
                {title}
            </h2>
            <div className="SeparatorLine"/>
        </div>

    );
    }
export default Separator;